import Api from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { Device } from '@twilio/voice-sdk';
import { useEffect, useState } from 'react';
import SuccessTone from 'containers/PhoneCall/sound/success.mp3';
import FinishTone from 'containers/PhoneCall/sound/finish.mp3';
import {
  phoneCallCalling,
  phoneCallCancelled,
  phoneCallClear,
  phoneCallConnected,
  phoneCallDeclined,
  phoneCallError,
  phoneCallFinished,
  setIsMute,
} from 'state/phoneCall/phoneCall.slice';

const usePhoneCall = () => {
  const dispatch = useDispatch();

  const [token, setToken] = useState<string>('');
  const [device, setDevice] = useState<any>(null);
  const [phoneCallData, setPhoneCallData] = useState<any>(null);
  const [consultationType, setConsultationType] = useState<string>('');
  const [consultationId, setConsultationId] = useState<string>('');
  const { status } = useSelector((state: any) => state.phoneCall);

  let interval: any;

  const startPhoneCall = ({
    userHash,
    consultationType,
    consultationId,
  }: {
    userHash: string;
    consultationType?: string;
    consultationId?: string;
  }) => {
    if (['idle', 'finished', null].includes(status)) {
      Api.phoneCalls.getToken().then(async (data) => {
        const { token } = data.data.data;
        setToken(token);
        consultationId && setConsultationId(consultationId);
        consultationType && setConsultationType(consultationType);
        Api.phoneCalls.callSetup({ userHash }).then((data) => {
          setPhoneCallData(data.data.data);
          dispatch(phoneCallCalling({ phoneCallData: data.data.data }));
        });
      });
    }
  };

  useEffect(() => {
    if (token && phoneCallData) {
      const device = new Device(token, {
        logLevel: 1,
        sounds: {
          outgoing: SuccessTone,
          disconnect: FinishTone,
        },
      });
      device.register();
      device.on('ready', () => {
        setDevice(device);
      });

      device.on('connect', () => {
        dispatch(phoneCallConnected());
      });
      device.on('disconnect', () => {
        finishPhoneCall();
      });

      device.on('cancel', () => {
        cancelPhoneCall();
      });
      device.on('reject', () => {
        declinePhoneCall();
      });
      device.on('registered', function () {
        setDevice(device);
      });

      return () => {
        setDevice(null);
      };
    }
  }, [token, phoneCallData]);

  useEffect(() => {
    if (device) {
      phoneCallData && makeOutgoingCall();
    }
  }, [device]);

  useEffect(() => {
    if (status === 'finished') {
      device && device.destroy();
      setDevice(null);
      setPhoneCallData(null);
      clearInterval(interval);
      setTimeout(() => {
        dispatch(phoneCallClear());
      }, 3200);
    }
  }, [status]);

  async function makeOutgoingCall() {
    const { from, to } = phoneCallData;

    const params = {
      To: to.full_phone_number,
      From: from.full_phone_number,
      patient_hash: to.hash,
      professional_hash: from.hash,
      consultation_type: consultationType,
      consultation_id: consultationId,
    };

    if (device) {
      const call = await device.connect({ params });

      call.on('accept', () => {
        connectPhoneCall();
      });

      call.on('error', () => {
        device.destroy();
        setDevice(null);
        errorPhoneCall();
      });
      call.on('disconnect', () => {
        finishPhoneCall();
      });

      const remoteHangup = document.getElementById('call-hangup');
      if (remoteHangup) {
        remoteHangup!.onclick = () => {
          call.disconnect();
        };
      }

      const remoteAudio = document.getElementById('mute-audio');
      if (remoteAudio) {
        remoteAudio!.onclick = () => {
          if (call && call.isMuted()) {
            call.mute(false);
            dispatch(setIsMute({ isMuted: call.isMuted() }));
          } else {
            call.mute(true);
            dispatch(setIsMute({ isMuted: call.isMuted() }));
          }
        };
      }

      call._onConnected = () => {
        connectPhoneCall();
      };
    } else {
      errorPhoneCall();
    }
  }

  const declinePhoneCall = () => {
    dispatch(phoneCallDeclined());
  };

  const finishPhoneCall = () => {
    const device = new Device(token);
    device.disconnectAll();
    dispatch(phoneCallFinished());
  };

  const cancelPhoneCall = () => {
    dispatch(phoneCallCancelled());
  };

  const connectPhoneCall = () => {
    dispatch(phoneCallConnected());
  };

  const errorPhoneCall = () => {
    dispatch(phoneCallError());
  };

  const handleHangUp = () => {
    const remoteHangup = document.getElementById('call-hangup');
    remoteHangup!.click();
    finishPhoneCall();
  };

  const mutePhoneCall = () => {
    const muteRemote = document.getElementById('mute-audio');
    muteRemote!.click();
  };

  return {
    startPhoneCall,
    handleHangUp,
    mutePhoneCall,
  };
};

export default usePhoneCall;
