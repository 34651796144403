import api from 'api';
import { newMessageNotification } from 'containers/Room/actions/NewMessage.actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import firebase from 'utils/firebase';
import Tracker from 'utils/Tracking';

const usePushNotifications = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!window.Notification || window.Notification.permission !== 'granted') {
      return;
    }

    firebase
      ?.getToken()
      .then((token) => api.notifications.register({ token, platform: 'web' }));

    navigator.serviceWorker.addEventListener('message', (message) => {
      const payload = JSON.parse(
        message?.data?.firebaseMessaging?.payload?.data?.data
      );

      if (payload?.type === 'message_created') {
        Tracker.event('remote message received', {
          action_type: 'debug',
          event_message_room_id: payload.room_id,
          event_message_id: payload.message_id,
          event_remote_message_id: payload.message_id,
        });
        dispatch(newMessageNotification({ room_id: payload.room_id }));
      }
    });
  }, []);
};

export default usePushNotifications;
