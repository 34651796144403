import { createSlice } from '@reduxjs/toolkit';

export interface LocalMedicalReport {
  id: string;
  room_id: number;
  subjective: string;
  objective: string;
  diagnostic_id: string;
  plan?: string;
  consultation_id?: string;
  consultation_type?: string;
}

interface MedicalReportsState {
  medicalReports: LocalMedicalReport[];
}

const initialState: MedicalReportsState = {
  medicalReports: [],
};

const slice = createSlice({
  name: 'medicalReports',
  initialState,
  reducers: {
    fetchMedicalReports: (state: MedicalReportsState, action) => {
      state.medicalReports = action.payload;
    },
    addMedicalReport: (state: MedicalReportsState, action) => {
      const index = state.medicalReports.findIndex(
        (item: LocalMedicalReport) => item.room_id === action.payload.room_id
      );
      if (index !== -1) {
        state.medicalReports[index] = action.payload;
      } else {
        state.medicalReports.push(action.payload);
      }
    },
    updateMedicalReport: (state: MedicalReportsState, action) => {
      const index = state.medicalReports.findIndex(
        (item: LocalMedicalReport) => item.id === action.payload.id
      );
      state.medicalReports[index] = action.payload;
    },
    deleteMedicalReport: (state: MedicalReportsState, action) => {
      state.medicalReports = state.medicalReports.filter(
        (item: LocalMedicalReport) => item.id !== action.payload.id
      );
    },
  },
});

export const {
  addMedicalReport,
  deleteMedicalReport,
  fetchMedicalReports,
  updateMedicalReport,
} = slice.actions;
export default slice.reducer;
