import axios from 'axios';
import { config } from 'config';
import getHeaders from 'utils/getHeaders';

export const BASE_URL = config.services.mediquo.chat_url;
export const FLASH = 'flash/v1';
export const CONSULTATIONS = 'consultations/v1';

type GetDocumentationRQ = {
  id: string;
  type: string;
};

type DownloadDocumentationRQ = {
  url: string;
};

const get = ({ id, type }: GetDocumentationRQ) =>
  axios.get(
    `${BASE_URL}/${['chat'].includes(type) ? CONSULTATIONS : FLASH}/${
      ['appointment'].includes(type) ? `${type}s` : type
    }/${id}/documents`,
    {
      headers: { ...getHeaders() },
    }
  );

const download = ({ url }: DownloadDocumentationRQ) =>
  axios.get(url, {
    headers: getHeaders(),
    responseType: 'blob',
  });

export default {
  get,
  download,
};
