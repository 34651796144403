import { config } from 'config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getHeaders from 'utils/getHeaders';

const SCHEDULES_BASE_URL = `${config.services.mediquo.chat_url}/schedules/v1`;

export type ScheduleDaysOfWeek =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'
  | 'working_week'
  | 'weekend'
  | 'all_week';

export interface ScheduleGap {
  from_time: string;
  to_time: string;
  days_of_week: ScheduleDaysOfWeek;
}

export interface GetChatScheduleResponse {
  id: string;
  gaps: Array<ScheduleGap>;
  timezone: string;
  starts_at: string;
  ends_at: string;
}
type GetChatScheduleRequest = void;

export interface PostChatScheduleRequest {
  timezone: string;
  gaps: Array<ScheduleGap>;
  starts_at: string;
  finishes_at?: string;
}

export type VideoCallStatus = 'available' | 'unavailable' | 'scheduled';

export type ScheduleChannel = 'videocall' | 'chat';

export interface GetVideoCallSchedulesRequest {
  status?: VideoCallStatus;
}
export type GetVideoCallSchedulesResponse = Array<{
  id: string;
  name: string;
  gaps: Array<ScheduleGap>;
  channel: ScheduleChannel;
  starts_at: string;
  finishes_at?: string;
  timezone: string;
}>;

export const schedulesApi = createApi({
  reducerPath: 'schedulesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SCHEDULES_BASE_URL,
    prepareHeaders: (headers) => {
      Object.entries(getHeaders()).forEach(([key, value]) =>
        headers.set(key, value!)
      );
      return headers;
    },
  }),
  tagTypes: ['ChatSchedules', 'VideoCallSchedules'],
  endpoints: (builder) => ({
    getChatSchedules: builder.query<
      GetChatScheduleResponse,
      GetChatScheduleRequest
    >({
      query: () => ({ url: '/chat' }),
      providesTags: ['ChatSchedules'],
      transformResponse: (response: { data: GetChatScheduleResponse }) =>
        response.data,
    }),

    getVideoCallSchedules: builder.query<
      GetVideoCallSchedulesResponse,
      GetVideoCallSchedulesRequest
    >({
      query: ({ status }) => ({ url: '/video-call', params: { status } }),
      providesTags: ['VideoCallSchedules'],
      transformResponse: (response: { data: GetVideoCallSchedulesResponse }) =>
        response.data,
    }),

    postChatSchedules: builder.mutation<void, PostChatScheduleRequest>({
      query: (data) => ({
        url: '/chat',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['ChatSchedules'],
    }),
  }),
});

export const {
  useGetChatSchedulesQuery,
  usePostChatSchedulesMutation,
  useGetVideoCallSchedulesQuery,
} = schedulesApi;
