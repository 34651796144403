import { createSelector } from '@reduxjs/toolkit';

const getMedicalReports = (state: any) => state.medicalReports.medicalReports;

export const getDraftReports = createSelector(getMedicalReports, (reports) =>
  reports?.filter((report: any) => report.status === 'draft')
);

export const getCurrentDraftReport = createSelector(
  getMedicalReports,
  (reports) => reports?.find((report: any) => report.status === 'draft')
);
