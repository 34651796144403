export interface Props {
  type: string;
  title: string;
  actions: JSX.Element;
}

const Document = ({ type, title, actions }: Props) => {
  return (
    <div className="flex justify-between items-center px-4 py-2 bg-[#353535] hover:bg-gray-darker group transition-colors rounded-md text-sm truncate text-[#989898] hover:text-white">
      <div className="inline-flex">
        <span className="font-medium text-sm">{type}</span>
        <div className="ml-2 truncate max-w-[230px]">{title}</div>
      </div>
      {actions}
    </div>
  );
};

export default Document;
