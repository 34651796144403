import classnames from 'classnames';
import { config } from 'config';
import useTimer from 'containers/Appointments/useTimer';
import useControls, { Background } from 'containers/Appointments/useControls';
import PatientBar from 'containers/DetailBar/PatientBar/PatientBar';
import RoomBody from 'containers/Room/components/RoomBody/RoomBody';
import RoomInput from 'containers/Room/components/RoomInput/RoomInput';
import {
  CameraControl,
  MicrophoneControl,
  Timer,
} from 'containers/VideoCall/CallView';
import {
  preloadScript,
  createSession,
  OTPublisher,
  OTSubscriber,
} from 'opentok-react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HangUpIcon from 'containers/VideoCall/icons/HangUpIcon';
import User from 'components/icons/outline/User';
import classNames from 'classnames';
import ChatBadge from 'components/icons/outline/ChatBadge';
import { joinRoom } from 'containers/Room/actions/JoinRoom.actions';
import { useTranslation } from 'react-i18next';
import Reporting from 'containers/Appointments/Reporting';
import Cross from 'components/icons/outline/Cross';
import MedicalReportCall from 'containers/MedicalReport/MedicalReportCall';
import TrackerCall from 'utils/TrackingCall';
import Tracker from 'utils/Tracking';
import HangUpIconDisabled from 'containers/VideoCall/icons/HangUpIconDisabled';
import Caret from 'components/icons/outline/Caret';
import Flag from 'components/icons/outline/Flag';
import NotPresentModal from 'components/Appointment/NotPresentModal';
import { usePortal } from 'containers/Shared/components/Portal';
import FlagSolid from 'components/icons/solid/FlagSolid';
import useAppointment from 'containers/Appointments/useAppointment';
import useMedicalReport from 'containers/MedicalReport/useMedicalReport';
import { useHistory } from 'react-router';
import Confirmation from 'components/Modal/Confirmation';
import { useForm } from 'react-hook-form';
import uuid from 'uuid/v4';
import NetworkExclamation from 'components/icons/outline/NetworkExclamation';
import {
  useGetAllReportsQuery,
  useUpsertDraftReportMutation,
} from 'services/reports';
import Spinner from 'components/Spinner';
import CheckIcon from 'containers/Login/icons/CheckIcon';
import ScreenHeader from 'containers/DetailBar/PatientBar/components/common/ScreenHeader';
import background from 'containers/Appointments/Meet/officeBackground.jpeg';
import BackgroundDropdown from 'containers/Appointments/Meet/BackgroundDropdown';

const videoFilterConfig = {
  image: {
    type: 'backgroundReplacement',
    backgroundImgUrl: background,
  },
  blur: {
    type: 'backgroundBlur',
    blurStrength: 'high',
  },
};

const Meet = ({
  session,
  roomId,
  onFinish,
  onExit,
  consultationType,
  consultationId,
  status,
  patient,
  appointment,
  isImmediateVideoCall,
}) => {
  const room = useSelector((state) => state.console.current);
  const [tab, setTab] = useState('profile');
  const [isPresent, setIsPresent] = useState();
  const seconds = useTimer({ callStartedAt: appointment.started_at });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [opentok, setOpentok] = useState();
  const [streams, setStreams] = useState([]);
  const [devices, setDevices] = useState([]);
  const [videoSource, setVideoSource] = useState({});
  const [hoverNotPresent, setHoverNotPresent] = useState(false);
  const otPublisherRef = useRef();
  const portal = usePortal();
  const { notPresent } = useAppointment();
  const { no_show } = appointment;
  const history = useHistory();
  const inCall = useSelector((state) => state.waitingRoom.inCall);

  // NOTE: OT is accessible from here as <Meet /> is wrapped with preloadScript
  // eslint-disable-next-line no-undef
  const hasMediaProcessorSupport = OT.hasMediaProcessorSupport();
  const controls = useControls();

  const [profileCollapsed, setProfileCollapsed] = useState(false);
  const [hasConnected, setHasConnected] = useState(false);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'POP' && inCall) {
        portal.open(
          <Confirmation
            title={t('videocall__navigation_modal_title')}
            content={t('videocall__navigation_modal_content')}
            confirmText={t('videocall__navigation_modal_confirm_text')}
            onConfirm={() => {
              portal.close('confirmation');
            }}
          />,
          'confirmation'
        );
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, []);

  useEffect(() => {
    TrackerCall.event('access to call');
    if (appointment.started_at) {
      setHasConnected(true);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPresent) {
        setIsPresent(false);
      }
    }, 3 * 1000);

    return () => clearInterval(interval);
  }, [isPresent]);

  useEffect(() => {
    const openTokSession = createSession({
      apiKey: config.services.tokbox.api_key,
      sessionId: session.id,
      token: session.token,
      onStreamsUpdated: setStreams,
    });
    const ses = openTokSession.session;
    setOpentok(ses);

    ses.on('sessionConnected', (event) => {
      TrackerCall.debug('session connected', {
        content: JSON.stringify({
          sessionId: event.target.sessionId,
          currentState: event.target.currentState,
          previousState: event.target.previousState,
        }),
      });
    });
    ses.on('sessionDisconnected', (event) => {
      TrackerCall.debug('session disconnected', {
        content: JSON.stringify({
          sessionId: event.target.sessionId,
          currentState: event.target.currentState,
          previousState: event.target.previousState,
        }),
      });
    });
    ses.on('sessionReconnected', (event) => {
      TrackerCall.debug('session reconnected', {
        content: JSON.stringify({
          sessionId: event.target.sessionId,
          currentState: event.target.currentState,
          previousState: event.target.previousState,
        }),
      });
    });
    ses.on('sessionReconnecting', (event) => {
      TrackerCall.debug('session reconnecting', {
        content: JSON.stringify({
          sessionId: event.target.sessionId,
          currentState: event.target.currentState,
          previousState: event.target.previousState,
        }),
      });
    });
    ses.on('streamCreated', (event) => {
      setIsPresent(true);
      setHasConnected(true);

      TrackerCall.debug('stream created', {
        content: JSON.stringify({
          event: event,
          sessionId: event.target.sessionId,
          currentState: event.target.currentState,
          previousState: event.target.previousState,
          streamHasAudio: event.stream.hasAudio,
          streamHasVideo: event.stream.hasVideo,
          streamVideoType: event.stream.videoType,
        }),
      });
    });
    ses.on('streamDestroyed', (event) => {
      setStreams([]);
      setIsPresent(false);

      TrackerCall.debug('stream destroyed', {
        content: JSON.stringify({
          sessionId: event.target.sessionId,
          currentState: event.target.currentState,
          previousState: event.target.previousState,
          streamReason: event.reason,
          streamHasAudio: event.stream.hasAudio,
          streamHasVideo: event.stream.hasVideo,
          streamVideoType: event.stream.videoType,
        }),
      });
    });
    ses.on('streamPropertyChanged', (event) => {
      TrackerCall.debug('stream property changed', {
        content: JSON.stringify({
          sessionId: event.target.sessionId,
          currentState: event.target.currentState,
          previousState: event.target.previousState,
          changedProperty: event.changedProperty,
          newValue: event.newValue,
          oldValue: event.oldValue,
        }),
      });
    });

    return () => openTokSession.disconnect();
  }, []);

  useEffect(() => {
    dispatch(joinRoom(roomId));
  }, []);

  const getDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    setDevices(devices.filter((device) => device.kind === 'videoinput'));
    setVideoSource(devices.find((device) => device.kind === 'videoinput'));
  };

  useEffect(() => {
    getDevices();
  }, []);

  if (!opentok) {
    return null;
  }

  const onCamaraSelect = (device) => {
    if (otPublisherRef) {
      const otPublisher = otPublisherRef.current.getPublisher();
      device.deviceId
        ? otPublisher.setVideoSource(device.deviceId).then().catch()
        : otPublisher.cycleVideo().then().catch();
      setVideoSource(
        devices.find((deviceItem) => deviceItem.deviceId === device.deviceId)
      );
    }
  };

  const handleChangeBackground = (value) => {
    const publisher = otPublisherRef.current.getPublisher();

    if (value === Background.Blur) {
      publisher.applyVideoFilter(videoFilterConfig.blur);
    } else if (value === Background.Image) {
      publisher.applyVideoFilter(videoFilterConfig.image);
    } else {
      publisher.clearVideoFilter();
    }

    controls.setBackground(value);
  };

  const markAsNotPresent = async () => {
    await notPresent({ appointmentId: appointment.id });
    await portal.close('modal_not_present');
    await setTimeout(() => onExit(), 2000);
  };

  const openNotPresentModal = () => {
    portal.open(
      <NotPresentModal
        handleClose={() => portal.close('modal_not_present')}
        handleConfirm={markAsNotPresent}
      />,
      'modal_not_present'
    );
  };

  return (
    <div
      className={classnames('flex w-full relative gap-3 py-3 pl-7', {
        'px-7': !profileCollapsed,
      })}
    >
      <div className="w-full bg-white">
        <div
          id="meet_wrap"
          className="relative h-full rounded-24 overflow-hidden bg-background-dark"
        >
          {status === 'reporting' ? (
            <Reporting
              patient={patient}
              room={room}
              status={status}
              consultationType={consultationType}
              consultationId={consultationId}
              appointment={appointment}
              isAppointmentAction={false}
              setProfileCollapsed={setProfileCollapsed}
              setTab={setTab}
            />
          ) : (
            <div className="h-full">
              <span className="absolute top-6 left-6 z-10 flex flex-col text-sm text-white">
                {isPresent ? (
                  patient.name
                ) : !isImmediateVideoCall ? (
                  <button
                    className="flex flex-row justify-center items-center"
                    onClick={onExit}
                  >
                    <Caret className="mr-2 w-2.5 rotate-90" />
                    {t('consultations__meet_not_present_return')}
                  </button>
                ) : hasConnected ? (
                  patient.name
                ) : (
                  <div />
                )}
              </span>

              <span className="absolute top-6 right-6 flex flex-col z-10">
                {isImmediateVideoCall ? (
                  hasConnected ? (
                    <Timer
                      seconds={seconds}
                      className="ml-auto inline-flex mb-2 font-medium text-blue bg-blue-light text-sm"
                    />
                  ) : (
                    <div />
                  )
                ) : isPresent ? (
                  <Timer
                    seconds={seconds}
                    className="ml-auto inline-flex mb-2 font-medium text-blue bg-blue-light text-sm"
                  />
                ) : (
                  !no_show && (
                    <button
                      className="flex flex-row text-white text-sm items-center"
                      onClick={openNotPresentModal}
                      onMouseEnter={() => setHoverNotPresent(true)}
                      onMouseLeave={() => setHoverNotPresent(false)}
                    >
                      <div className="w-fit">
                        <span
                          className={classNames({
                            'border-b border-white': hoverNotPresent,
                          })}
                        >
                          {t('consultations__meet_not_present_button')}
                        </span>
                      </div>
                      <div className="w-8 h-8 bg-[#404040] ml-1 p-2 rounded-full">
                        {hoverNotPresent ? (
                          <FlagSolid className="w-4 -translate-y-3 " />
                        ) : (
                          <Flag className="w-4" />
                        )}
                      </div>
                    </button>
                  )
                )}
              </span>
              <div className="absolute bottom-6 left-6 z-10">
                <OTPublisher
                  session={opentok}
                  properties={{
                    audioFallbackEnabled: false,
                    showControls: false,
                    fitMode: 'cover',
                    publishAudio: controls.isAudioEnabled,
                    publishVideo: controls.isVideoEnabled,
                    ...(!!controls.backgroundFilter && {
                      videoFilter: videoFilterConfig[controls.backgroundFilter],
                    }),
                  }}
                  ref={otPublisherRef}
                />
              </div>

              <div className="absolute bottom-6 right-6 flex gap-2.5 z-10">
                <MicrophoneControl
                  toggle={controls.toggleAudio}
                  audio={controls.isAudioEnabled}
                />
                <CameraControl
                  toggle={controls.toggleVideo}
                  video={controls.isVideoEnabled}
                  onCamaraSelect={onCamaraSelect}
                  devices={devices}
                  videoSource={videoSource}
                />
                <BackgroundDropdown
                  value={controls.backgroundFilter}
                  onChange={handleChangeBackground}
                  disabled={!hasMediaProcessorSupport}
                />
                <button
                  disabled={
                    (isImmediateVideoCall && !hasConnected) ||
                    (!isImmediateVideoCall && !isPresent)
                  }
                  onClick={onFinish}
                  className={classnames(
                    'flex items-center justify-center rounded-2xl w-12 h-12',
                    {
                      'bg-negative':
                        (isImmediateVideoCall && hasConnected) ||
                        (!isImmediateVideoCall && isPresent),
                    }
                  )}
                >
                  {isImmediateVideoCall ? (
                    hasConnected ? (
                      <HangUpIcon />
                    ) : (
                      <HangUpIconDisabled />
                    )
                  ) : isPresent ? (
                    <HangUpIcon />
                  ) : (
                    <HangUpIconDisabled />
                  )}
                </button>
              </div>

              {streams.map((stream) => (
                <OTSubscriber
                  key={stream.id}
                  session={opentok}
                  stream={stream}
                  properties={{ showControls: false, fitMode: 'contain' }}
                  eventHandlers={{
                    streamCreated: () => setIsPresent(true),
                  }}
                />
              ))}
              {!isPresent && (
                <div className="w-full h-full flex items-center justify-center">
                  <div className="max-w-[300px] mx-auto text-center">
                    <h1 className="text-white text-2xl ">
                      {hasConnected && isImmediateVideoCall ? (
                        <div className="flex flex-row gap-2">
                          <NetworkExclamation />
                          {t('consultations__meet_connecting_title')}
                        </div>
                      ) : (
                        t('consultations__meet_not_present_title')
                      )}
                    </h1>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className={classnames('flex h-full', {
          'min-w-[436px] w-[436px] max-w-[436px] flex-shrink-0 ':
            !profileCollapsed,
        })}
      >
        <div className="flex flex-col gap-2">
          <TabButton
            isActive={tab === 'profile' || tab === 'report'}
            onClick={() => {
              setTab('profile');
              setProfileCollapsed(false);
            }}
            profileCollapsed={profileCollapsed}
          >
            <User className="h-6 w-6" />
          </TabButton>
          <TabButton
            isActive={tab === 'chat'}
            onClick={() => {
              setTab('chat');
              setProfileCollapsed(false);
            }}
            profileCollapsed={profileCollapsed}
          >
            <ChatBadge className="h-6 w-6" />
          </TabButton>
        </div>
        <div
          className={classnames({
            'h-full w-full flex flex-col border border-separators rounded-xl rounded-tl-none overflow-hidden':
              !profileCollapsed,
            hidden: profileCollapsed,
          })}
        >
          {
            {
              profile: (
                <Profile
                  roomId={roomId}
                  room={room}
                  consultationType={consultationType}
                  consultationId={consultationId}
                  setProfileCollapsed={setProfileCollapsed}
                  setTab={setTab}
                />
              ),
              chat: (
                <Chat
                  room={room}
                  consultationType={consultationType}
                  consultationId={consultationId}
                  setProfileCollapsed={setProfileCollapsed}
                />
              ),
              report: (
                <Report
                  roomId={roomId}
                  room={room}
                  consultationType={consultationType}
                  consultationId={consultationId}
                  setTab={setTab}
                />
              ),
            }[tab]
          }
        </div>
      </div>
    </div>
  );
};

const Chat = ({
  room,
  consultationType,
  consultationId,
  setProfileCollapsed,
}) => {
  return (
    <>
      <div className="flex flex-row justify-between p-6 border-b border-separators">
        <span className="text-dark">Chat</span>
        <button onClick={() => setProfileCollapsed(true)}>
          <Cross className="text-gray-medium w-3.5 ml-auto" />
        </button>
      </div>
      <RoomBody room={room} />
      <RoomInput
        room={room}
        consultationType={consultationType}
        consultationId={consultationId}
      />
    </>
  );
};

const Profile = ({
  roomId,
  consultationType,
  consultationId,
  setProfileCollapsed,
  setTab,
}) => {
  const { t } = useTranslation();
  const { screen } = useSelector((state) => state.patientBar);
  return (
    <div className="h-full">
      {!['report'].includes(screen) && (
        <div className="flex flex-row justify-between p-6 border-b border-separators">
          <span className="text-dark">
            {t('sidebar__patient_header_title')}
          </span>
          <button onClick={() => setProfileCollapsed(true)}>
            <Cross className="text-gray-medium w-3.5 ml-auto" />
          </button>
        </div>
      )}
      <PatientBar
        showHeader={false}
        roomId={roomId}
        width="full"
        consultationType={consultationType}
        consultationId={consultationId}
        setProfileCollapsed={setProfileCollapsed}
        setTab={setTab}
      />
    </div>
  );
};

const TabButton = ({ children, onClick, isActive, profileCollapsed }) => (
  <button
    className={classNames(
      'w-10 h-10 flex items-center justify-center rounded-tl-lg rounded-bl-lg',
      {
        'text-dark bg-secundary': !isActive || profileCollapsed,
        'text-white bg-primary': isActive && !profileCollapsed,
      }
    )}
    onClick={onClick}
  >
    {children}
  </button>
);

export const Report = ({
  roomId,
  room,
  consultationId,
  consultationType,
  setTab,
}) => {
  const { t } = useTranslation();
  const { handleChangeScreen } = useMedicalReport();
  const { data } = useGetAllReportsQuery(
    { patient_hash: room?.meta?.hash },
    { skip: !room?.meta?.hash }
  );
  const currentDraft = data?.draftReport;

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      subjective: currentDraft?.subjective_data,
      objective: currentDraft?.objective_data,
      diagnostic_id:
        currentDraft?.diagnostic?.id ?? currentDraft?.diagnostic_id,
      plan: currentDraft?.plan,
    },
  });
  const {
    formState: { isDirty },
    watch,
  } = methods;
  const formData = watch();
  const [mutateSaveDraft, { isLoading: isSavingDraft, isSuccess }] =
    useUpsertDraftReportMutation();
  const patientHash = room?.meta?.hash;

  const handleCancelReport = () => {
    if (isDirty) {
      Tracker.event('reports cancel', {
        event_room_id: room?.room_id,
      });

      mutateSaveDraft({
        report_id: currentDraft?.uuid ?? uuid(),
        data: {
          customer_hash: patientHash,
          consultation_id: consultationId,
          consultation_type: consultationType,
          plan: formData.plan,
          diagnostic_id: formData.diagnostic_id,
          objective_data: formData.objective,
          subjective_data: formData.subjective,
        },
      });
    }

    setTab?.('profile');
    handleChangeScreen('profile');
  };

  return (
    <>
      <ScreenHeader
        title={t('sidebar__patient_create_report')}
        onBack={handleCancelReport}
        endSection={
          <div className="inline-flex items-center text-sm font-light">
            {isSavingDraft ? (
              <>
                <span>{t('appointments__meet__saving_draft')}</span>
                <Spinner className="w-3 h-3 ml-2" />
              </>
            ) : isSuccess ? (
              <>
                <span>{t('appointments__meet__saved_draft')}</span>
                <CheckIcon className="w-3 h-3 ml-2" />
              </>
            ) : (
              t('sidebar__patient_clinical_courses_draft')
            )}
          </div>
        }
      />
      <MedicalReportCall
        formMethods={methods}
        setTab={setTab}
        roomId={roomId}
        patientHash={patientHash}
        consultation_type={consultationType}
        consultation_id={consultationId}
        onAutoSave={mutateSaveDraft}
      />
    </>
  );
};

export default preloadScript(Meet);
