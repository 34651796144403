import PlusIcon from 'components/icons/Plus';

export type Props = JSX.IntrinsicElements['button'] & {
  endSection?: JSX.Element;
};

const AddButton = ({ children, endSection, ...props }: Props) => {
  return (
    <button
      className="flex items-center justify-between p-3 bg-[#1B1B1B] text-white hover:bg-gray-darker tracking-wide normal-case rounded-md font-medium whitespace-nowrap text-sm min-w-fit"
      {...props}
    >
      <div className="inline-flex items-center">
        <div className="flex place-items-center justify-center w-4 h-4 text-blue bg-secundary rounded-md mr-[10px] font-normal shrink-0">
          <PlusIcon className="h-2 w-2" />
        </div>
        <span>{children}</span>
      </div>
      {endSection}
    </button>
  );
};

export default AddButton;
