import { FC } from 'react';
import classnames from 'classnames';
import useOutside from 'utils/useOutside';
import useAnimation from 'utils/useAnimation';
import useKeyPress from 'utils/useKeyPress';

export interface Props {
  onClose?: (value?: unknown) => void;
  onOutside?: ({ onClose }: { onClose: () => void }) => void;
  allowOutsideClose?: boolean;
  width?: 'narrow' | 'medium' | 'large' | 'huge' | 'custom';
  position?: 'top' | 'center';
  rounded?: 'small' | 'large';
  backdropTransparent?: boolean;
  className?: string;
  isTransparent?: boolean;
  backdropDark?: boolean;
}

interface Nested {
  Header: FC<HeaderProps>;
  Title: FC;
  Body: FC<BodyProps>;
  Footer: FC<FooterProps>;
  CloseButton: FC<CloseButtonProps>;
  BoxRounded: FC<BoxRoundedProps>;
}

const Modal: FC<Props> & Nested = ({
  children,
  onClose = () => {},
  onOutside = () => {},
  allowOutsideClose = true,
  width = 'large',
  position = 'center',
  rounded = 'small',
  backdropTransparent = false,
  className,
  isTransparent = false,
  backdropDark = false,
}) => {
  const { isOpen, close: closeAnimation } = useAnimation({
    onCloseFinish: onClose,
  });

  const contentRef = useOutside(() => {
    onOutside({ onClose: closeAnimation });
    allowOutsideClose && closeAnimation();
  });

  useKeyPress({
    key: 'Escape',
    onPress: closeAnimation,
  });

  return (
    <>
      <div
        className={classnames(
          'fixed inset-0 bottom-0 z-40 grid overflow-y-auto',
          {
            center: 'place-items-end sm:place-items-center',
            top: 'place-items-start',
          }[position]
        )}
      >
        <div className={classnames('z-[1] w-full p-4', 'sm:p-10')}>
          <div
            ref={contentRef}
            className={classnames(
              'modal',
              'relative text-dark m-auto shadow-xl overflow-hidden',
              'transition-all transform',
              isOpen ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0',
              {
                narrow: 'max-w-lg',
                medium: 'max-w-xl',
                large: 'max-w-3xl',
                huge: 'max-w-4xl',
                custom: '',
              }[width],
              { small: 'small rounded-lg', large: 'large rounded-24' }[rounded],
              isTransparent ? 'bg-transparent' : 'bg-white',
              className ?? ''
            )}
          >
            {typeof children === 'function'
              ? children({ onClose: closeAnimation })
              : children}
          </div>
        </div>
      </div>
      <div
        className={classnames(
          'fixed inset-0 z-30',
          'transition-opacity',
          isOpen ? 'opacity-75' : 'opacity-0',
          backdropTransparent
            ? 'transparent'
            : backdropDark
            ? 'bg-black'
            : 'bg-gray-medium'
        )}
      />
    </>
  );
};

interface HeaderProps {
  onClose?: () => void;
}

const Header: FC<HeaderProps> = ({ children, onClose }) => (
  <div
    className={classnames(
      'header',
      'flex items-center px-6 py-5 text-xl font-medium bg-white border-b text-dark border-separators'
    )}
  >
    {children}
    <CloseButton onClose={onClose} inline />
  </div>
);

interface CloseButtonProps {
  onClose?: () => void;
  inline?: boolean;
}

const CloseButton: FC<CloseButtonProps> = ({ onClose, inline = false }) => (
  <button
    onClick={(event) => {
      event.preventDefault();
      onClose?.();
    }}
    className={classnames(
      'flex items-center justify-center w-8 h-8 ml-auto rounded-lg cursor-pointer text-gray-medium',
      'hover:bg-gray-light hover:text-gray-dark',
      { 'absolute top-6 right-6 z-10': !inline }
    )}
  >
    <CrossIcon />
  </button>
);

const CrossIcon = () => (
  <svg
    className="fill-current"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
  </svg>
);

const Title: FC = ({ children }) => (
  <div className="mb-6 text-2xl font-medium text-primary">{children}</div>
);

interface BodyProps {
  type?: 'normal' | 'full' | 'empty';
  className?: string;
}

const Body: FC<BodyProps> = ({ children, type = 'normal', className = '' }) => (
  <div
    className={classnames(
      'body',
      'bg-white',
      { normal: 'px-6 py-4 mb-4', full: 'p-10', empty: '' }[type],
      className
    )}
  >
    {children}
  </div>
);

interface FooterProps {
  disclamer?: string;
  className?: string;
}

const Footer: FC<FooterProps> = ({ children, disclamer, className }) => (
  <div
    className={classnames(
      'footer',
      'py-4 px-6 flex flex-col gap-4 border-t border-separators',
      'sm:flex-row-reverse',
      { 'flex-col-reverse sm:items-center': disclamer },
      className
    )}
  >
    {children}
    {disclamer && (
      <p className="text-xs sm:mr-auto text-gray-dark">{disclamer}</p>
    )}
  </div>
);

interface BoxRoundedProps {
  negative?: boolean;
}

const BoxRounded: FC<BoxRoundedProps> = ({ children, negative = false }) => (
  <div
    className={classnames(
      {
        'bg-negative-light text-negative': negative,
        'bg-secundary text-primary': !negative,
      },
      'flex items-center justify-center m-4 h-80px w-80px rounded-24 shadow-lg'
    )}
  >
    <div
      className={classnames(
        {
          'bg-negative': negative,
          'bg-primary': !negative,
        },
        'flex items-center justify-center w-12 h-12 rounded-full text-white'
      )}
    >
      {children}
    </div>
  </div>
);

Modal.BoxRounded = BoxRounded;
Modal.CloseButton = CloseButton;
Modal.Header = Header;
Modal.Title = Title;
Modal.Body = Body;
Modal.Footer = Footer;
export default Modal;
