import Api from 'api';
import { useEffect, useState } from 'react';

const useDocumentation = ({ id, type }: { id: string; type: string }) => {
  const [documentation, setDocumentation] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);

  const get = () => {
    if (!id || !type) return;
    Api.documentation.get({ id, type }).then((data) => {
      const documentation = data.data.data;
      setDocumentation(documentation);
      documentation.length ? setIsEmpty(false) : setIsEmpty(true);
    });
  };

  useEffect(() => {
    get();
  }, [id, type]);

  return {
    get,
    documentation,
    isEmpty,
  };
};

export default useDocumentation;
