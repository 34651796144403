import Meet from 'containers/Appointments/Meet';
import Api from 'api';
import { useEffect, useState } from 'react';
import useAppointment from 'containers/Appointments/useAppointment';
import { useParams, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { joinRoom } from 'containers/Room/actions/JoinRoom.actions';
import { useDispatch } from 'react-redux';
import TrackerCall from 'utils/TrackingCall';
import { setNotInCall } from 'containers/WaitingRoom/actions/GetWaitingRoom.actions';
import { getRoom } from 'containers/Inbox/actions/GetRoomList.actions';

const AppointmentMeet = () => {
  const dispatch = useDispatch();

  const [appointmentMeet, setAppointmentMeet] = useState(false);
  const { get, reporting, inCall, status } = useAppointment();
  const { appointmentId } = useParams();
  const history = useHistory();
  const [isImmediateVideoCall, setIsImmediateVideoCall] = useState(false);

  const onFinish = () => {
    TrackerCall.event('leave call');

    reporting({ appointmentId: appointmentMeet.appointment.id });
  };

  useEffect(() => {
    if (!appointmentMeet) {
      get({ appointmentId }).then((data) => {
        let appointment = data.data.data;
        if (appointment.is_immediate_video_call) setIsImmediateVideoCall(true);
        dispatch(getRoom({ roomId: appointment.room_id }));
        dispatch(joinRoom(appointment.room_id));

        TrackerCall.setConsultation({
          consultationId: appointment.id,
          consultationType: 'appointment',
          callId: appointment.call_id,
          userHash: appointment.from.hash,
          contactId: appointment.to.id,
        });

        Api.calls.join({ callId: appointment.call_id }).then((data) => {
          inCall({ appointmentId: appointment.id });
          setAppointmentMeet({
            callAccess: data.data.data,
            appointment: {
              id: appointment.id,
              room_id: appointment.room_id,
              status: appointment.status,
              started_at: appointment.started_at,
              no_show: appointment.no_show,
            },
            patient: appointment.to,
          });
        });
      });
    }
  });

  if (!appointmentMeet) {
    return 'loading';
  }

  if (appointmentMeet.appointment.status === 'finished') {
    // todo Automate
    return (
      <Link to={'/waiting-room'} className="text-primary">
        Cita finalizada, volver a la Sala de espera
      </Link>
    );
  }

  return (
    <Meet
      patient={appointmentMeet.patient}
      session={{
        id: appointmentMeet.callAccess.session_id,
        token: appointmentMeet.callAccess.session_token,
      }}
      roomId={appointmentMeet.appointment.room_id}
      consultationType="appointment"
      consultationId={appointmentMeet.appointment.id}
      onFinish={onFinish}
      onSkip={() => {
        console.log('skip is not allowed here');
      }}
      appointment={appointmentMeet.appointment}
      isImmediateVideoCall={isImmediateVideoCall}
      onExit={() => {
        dispatch(setNotInCall());
        history.push('/appointments');
      }}
      status={status}
    />
  );
};

export default AppointmentMeet;
