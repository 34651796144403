import Button from 'components/Button';
import { CloseIcon } from 'components/Drawer';
import { useTranslation } from 'react-i18next';

export interface DraftMessageProps {
  open: boolean;
  onClose: () => void;
}

const DraftMessage = ({ open, onClose }: DraftMessageProps) => {
  const { t } = useTranslation();

  return open ? (
    <div className="p-4 bg-primary-darker text-white">
      <div className="flex justify-between">
        <h3 className="text-xs font-medium">
          {t('reports__draft_message_title')}
        </h3>
        <Button
          size="custom"
          variant="custom"
          className="text-white"
          onClick={onClose}
        >
          <CloseIcon className="h-3 w-3" />
        </Button>
      </div>
      <p className="mt-1 text-xs">{t('reports__draft_message_content')}</p>
    </div>
  ) : (
    <></>
  );
};

export default DraftMessage;
